import axios from 'axios'

const DocumentService = {
  async search (filters) {
    return axios({
      url: axios.defaults.baseURL + 'document/search',
      method: 'POST',
      data: JSON.stringify({ filters: filters })
    })
  },

  async createInit (id = 0, companyId = 0) {
    return axios({
      url: axios.defaults.baseURL + 'document/init',
      method: 'POST',
      data: JSON.stringify({ id: id, company_id: companyId })
    })
  },

  async update (formdata) {
    return axios.post(axios.defaults.baseURL + 'document/update', formdata, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  async delete (id) {
    return axios({
      url: axios.defaults.baseURL + 'document/delete/' + id,
      method: 'GET'
    })
  }
}

export default DocumentService
