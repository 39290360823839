<template>
  <div class="d-inline">
    <div @click="openModal" class="ma-0 pa-0 d-inline">
      <slot>
        <b-button
          v-if="edit"
          variant="warning"
          block
        >
          <b-icon icon="pencil"></b-icon>
          Edit Document
        </b-button>
        <b-button
          variant="success"
          v-else
          block
        >
          <b-icon icon="plus"></b-icon>
          Add Document
        </b-button>
      </slot>
    </div>

    <b-modal
      :id="'documentmodal' + documentId"
      :title="(edit ? 'Edit' : 'Add') + ' Document'"
      @hidden="reset()"
      @shown="init()"
      @ok="updateDocument"
      ok-variant="success"
      :busy="loading"
      no-close-on-backdrop
      no-close-on-esc
    >
    <b-form-group v-if="isBookkeeper" label="Document Type" label-align="left">
        <b-form-select :options="types" text-field="name" value-field="id" v-model="document.documenttype_id" @change="resetFields"></b-form-select>
      </b-form-group>

      <b-form-group v-if="isBookkeeper && showField('year')" label="Year" label-align="left">
        <b-input v-model="document.year"></b-input>
      </b-form-group>

      <b-form-group v-if="isBookkeeper && showField('month')" label="Month" label-align="left">
        <b-form-select :options="months" v-model="document.month">
          <template #first>
            <b-select-option :value="null" disabled>-- Select One --</b-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group v-if="isBookkeeper && showField('quarter')" label="Quarter" label-align="left">
        <b-form-select :options="quarters" v-model="document.quarter">
          <template #first>
            <b-select-option :value="null" disabled>-- Select One --</b-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group label="File" label-align="left">
        <b-input-group v-if="documentId > 0 && !deleteDoc && hasFile">
          <b-input
            v-model="existingFilename"
            disabled
          ></b-input>
          <b-input-group-append>
            <b-button variant="danger" @click="deleteDoc = true"><b-icon icon="x"></b-icon></b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="custom-file-container">
          <input
            type="file"
            id="fileInput"
            ref="fileInput"
            class="custom-file-input"
            @change="handleFiles"
            multiple
          >
          <label for="fileInput" class="custom-file-label">
            {{ fileLabel }}
          </label>
        </div>
      </b-form-group>

      <template #modal-ok>
        <b-icon icon="check"></b-icon> Save
      </template>
      <template #modal-cancel>
        <b-icon icon="x"></b-icon> Cancel
      </template>

      <b-overlay :show="loading" no-wrap></b-overlay>
    </b-modal>
  </div>
</template>
<script>
import DocumentService from '@/services/DocumentService'
import UserService from '@/services/UserService'
import moment from 'moment'

export default {
  props: {
    compId: {
      type: Number,
      default: 0
    },
    documentId: {
      type: Number,
      default: 0
    },
    edit: {
      type: Boolean,
      default: false
    },
    companyName: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      document: {
        documenttype_id: 40,
        files: [],
        year: moment().format('YYYY'),
        month: null,
        quarter: null
      },
      existingFilename: '',
      selectedFiles: [],
      fileLabel: 'Drop Files Here...',
      deleteDoc: false,
      hasFile: false,
      types: [],
      loading: false,
      quarters: [
        { value: 1, text: 'Q1' },
        { value: 2, text: 'Q2' },
        { value: 3, text: 'Q3' },
        { value: 4, text: 'Q4' }
      ],
      months: [
        { text: 'January', value: 1 },
        { text: 'February', value: 2 },
        { text: 'March', value: 3 },
        { text: 'April', value: 4 },
        { text: 'May', value: 5 },
        { text: 'June', value: 6 },
        { text: 'July', value: 7 },
        { text: 'August', value: 8 },
        { text: 'September', value: 9 },
        { text: 'October', value: 10 },
        { text: 'November', value: 11 },
        { text: 'December', value: 12 }
      ],
      isBookkeeper: false
    }
  },

  methods: {
    init () {
      this.loading = true
      DocumentService.createInit(this.documentId, this.compId).then(
        (response) => {
          if (response && response.data && response.data.info) {
            this.types = response.data.info.doctypes

            if (response.data.info.document) {
              const doc = response.data.info.document
              this.document = {
                documenttype_id: doc.documenttype_id,
                year: doc.year,
                month: doc.month,
                quarter: doc.quarter
              }
              this.hasFile = doc.document_id > 0
              this.existingFilename = doc.documentname

              if (!this.hasFile) {
                this.document.documenttype_id = 30
              }
            } else {
              this.reset()
            }
          }
        }
      ).finally(() => { this.loading = false })
      if (UserService.isAdmin() || UserService.isBookkeeper()) {
        this.isBookkeeper = true
      } else {
        this.document.documenttype_id = 42
        this.isBookkeeper = false
      }
    },

    handleFiles (event) {
      // Clear the previous selection
      this.selectedFiles = []

      // Update the selectedFiles array with the new files
      if (event.target.files.length > 0) {
        for (let i = 0; i < event.target.files.length; i++) {
          this.selectedFiles.push(event.target.files[i])
        }
        // Update the label with the names of the selected files
        this.fileLabel = Array.from(event.target.files)
          .map(file => file.name)
          .join(', \n')
      } else {
        // Reset the label if no files are selected
        this.fileLabel = 'Choose files...'
      }
    },

    updateDocument (bvModalEvt) {
      // Prevent the default form submission behavior
      bvModalEvt.preventDefault()
      if (this.isBookkeeper) {
        if (!this.validateFileNames(this.companyName, this.selectedFiles)) {
          alert('The filename does not match the company name requirements. Please check and try again.')
          return
        }
      }
      // Start the loading process
      this.loading = true

      // Initialize FormData
      const formData = new FormData()

      // Append the document type ID, deleteDoc flag, and other metadata to FormData
      formData.append('documenttype_id', this.document.documenttype_id)
      formData.append('deleteDoc', this.deleteDoc)
      formData.append('quarter', this.document.quarter)
      formData.append('month', this.document.month)
      const yearValue = this.document.documenttype_id !== 40 ? this.document.year : null
      formData.append('year', yearValue)

      // Append selected files to FormData
      this.selectedFiles.forEach((file, index) => {
        formData.append('files[]', file)
      })

      // Append IDs if editing
      if (this.edit && this.documentId > 0) {
        formData.append('id', this.documentId)
      }

      // Append company ID
      if (this.compId > 0) {
        formData.append('company_id', this.compId)
      }

      // Make the API call to update the document
      DocumentService.update(formData)
        .then(response => {
          // Handle the response
          this.$aimNotify.notify(response)
          this.$emit('refresh')
          this.$nextTick(() => {
            this.$bvModal.hide('documentmodal' + this.documentId)
          })
        })
        .catch(err => {
          // Handle any errors
          this.$aimNotify.error(err.response)
        })
        .finally(() => {
          // Stop the loading process
          this.selectedFiles = []
          this.fileLabel = 'Drop Files Here...'
          this.loading = false
        })
    },

    validateFileNames (companyName, files) {
      console.log(companyName)
      const nameParts = companyName.trim().split(/\s+/)
      console.log('Split nameParts:', nameParts)
      const escapedNameParts = nameParts.map(part => this.escapeRegExp(part))
      console.log('Escaped nameParts:', escapedNameParts)
      const regexPattern = `^${escapedNameParts.join('\\s+')}`
      console.log('Final regexPattern:', regexPattern)
      const regex = new RegExp(regexPattern, 'i')
      console.log('Regex:', regex)
      return files.every(file => regex.test(file.name))
    },

    escapeRegExp (string) {
      return string.replace(/[-.*+?^${}()|[\]\\]/g, '\\$&')
    },

    openModal () {
      this.selectedFiles = []
      this.fileLabel = 'Drop Files Here...'
      this.$bvModal.show('documentmodal' + this.documentId)
    },

    reset () {
      this.document = {
        documenttype_id: 42,
        year: moment().format('YYYY'),
        month: null,
        quarter: null,
        file: null
      }
      this.existingFilename = ''
      this.deleteDoc = false
    },

    resetFields () {
      this.document.year = moment().format('YYYY')
      this.document.month = null
      this.document.quarter = null
    },

    showField (field) {
      return this.fields().includes(field)
    },

    fields () {
      const items = this.types.filter(el => { return +el.id === +this.document.documenttype_id })
      if (items.length > 0) {
        const fields = JSON.parse(items[0].fields)
        return fields || []
      } else {
        return []
      }
    }

  }

}
</script>

<style scoped>
.custom-file-container {
  position: relative;
  width: 100%;
}

.custom-file-input {
  width: 100%;
  min-height: 150px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  opacity: 0; /* Hide the default HTML file input */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2; /* Ensure it's above the label so it can capture clicks */
}

.custom-file-label {
  display: block;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  min-height: 150px; /* Match your custom file input height */
  line-height: 1.5;
  text-align: left;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  overflow: hidden;
  white-space: normal;
}

</style>
