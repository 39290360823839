<template>
  <b-button class="back-button float-left" @click="goBack()" variant="light"><b-icon icon="arrow-left"></b-icon></b-button>
</template>
<script>
export default {
  props: {
    fallback: {
      type: String,
      default: '/'
    }
  },

  methods: {
    goBack () {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        this.$router.push(this.fallback)
      }
    }
  }
}
</script>
